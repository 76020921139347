<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import customerService from '@/api/customerService';
import Swal from 'sweetalert2';
import moment from 'moment';
import staffService from '@/api/staffService';
import CommentSection from '@/components/comment-section.vue';
import systemService from '@/api/systemService';

export default {
	name: 'require-install',
	props: {
		form_data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		role_id: {
			type: Number,
			required: true
		},
		getData: {
			type: Function,
			required: true
		},
		edit_permission: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			customer_details: {},
			vehicle_details: {},
			installers: [],
			veh_color: null,
			odometer: null,
			spare_key: null,
			installer_id: null,
			book_date: null,
			book_time: null,
			remarks: '',
			comments: [],
			imei_live: null,
			is_confirmed: false,
		};
	},
	computed: {
		customer_id() {
			return this.$route.query.id;
		},
		notMadeChanges() {
			if (this.form_data?.book_installer) {
				return (
					this.veh_color === this.form_data.book_installer?.veh_color &&
					this.odometer === this.form_data.book_installer?.odometer &&
					this.spare_key === this.form_data.book_installer?.spare_key &&
					this.installer_id === this.form_data.book_installer?.installer_id &&
					this.remarks === this.form_data.book_installer?.remarks &&
					this.book_date ===
						moment(this.form_data.book_installer?.book_datetime).format(
							'YYYY-MM-DD'
						) &&
					this.book_time ===
						moment(this.form_data.book_installer?.book_datetime).format('HH:mm')
				);
			} else {
				return false;
			}
		}
	},
	methods: {
		changes() {
			clearTimeout(this.changesTimeout);
				this.changesTimeout = setTimeout(() => {
					this.saveRequireInstall()
				}, 2000);
			},
		getAcceptedTime({ date, time}) {
			this.book_date = date
			this.book_time = time

			Swal.fire({
				title: 'Are you sure?',
				text: 'You are about to change the booking time',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.saveRequireInstall(true)
				}
			})
		},
		formValidation(e, type = 'text') {
			if (this.edit_permission) {
				return null
			} else {
				const emailRegex = /\S+@\S+\.\S+/;

				switch (type) {
					case 'email':
						return (
							e !== null && e !== undefined && e !== '' && emailRegex.test(e)
						);
					default:
						return e !== null && e !== undefined && e !== '';
				}
			}
		},
		async saveRequireInstall(is_confirmed) {
			const body = {
				veh_color: this.veh_color,
				odometer: this.odometer,
				spare_key: this.spare_key,
				installer_id: this.installer_id,
				remarks: this.remarks,
				book_datetime: this.book_date + ' ' + this.book_time,
			};

			if (is_confirmed) {
				body.is_confirmed = 1
			}
			
			const response = await customerService.requireInstall(
				this.customer_id,
				body
			);
			if (response) {
				Swal.fire({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000,
					icon: 'success'
				});
			}
		},
		async checkIMEI(imei) {
			this.checking = true
			await systemService.checkIMEI(imei)
				.then(res => {
					this.checking = false
					this.imei_live = res.data
				})
		},
		detectData() {
			if (this.form_data) {
				const {
					name: customer_name,
					phone_no: customer_phone,
					location: customer_location,
					enquiry: { road_tax_expiry },
					comments
				} = this.form_data;
				this.customer_details = {
					customer_name,
					customer_phone,
					customer_location
				};

				if (this.form_data.vehicle) {
					const {
						vehicle: {
							brand: { name: vehicle_brand },
							vehicle_model: { name: vehicle_model },
							veh_regis_no,
							veh_trans,
							imei
						}
					} = this.form_data

					this.vehicle_details = {
						brand: vehicle_brand,
						model: vehicle_model,
						veh_regis_no,
						veh_trans,
						road_tax_expiry,
						imei
					};

					if (imei) {
						this.checkIMEI(imei)
					}
				}
		
				if (this.form_data.book_installer) {
					const { 
						book_installer: {
							veh_color,
							odometer,
							spare_key,
							installer_id,
							remarks,
							book_datetime,
							is_confirmed
						}
					} = this.form_data

					this.veh_color = veh_color;
					this.odometer = odometer;
					this.spare_key = spare_key;
					this.installer_id = installer_id;
					this.remarks = remarks;
					this.is_confirmed = is_confirmed == 1

					this.book_date = book_datetime
						? moment(book_datetime).format('YYYY-MM-DD')
						: null;
					this.book_time = book_datetime
						? moment(book_datetime).format('HH:mm')
						: null;
				}
				
				this.comments = comments;
			}
		},
		assignData() {
			setTimeout(() => {
				this.detectData();
			}, 1000);
		},
		show_date(datetime) {
			return datetime ? moment(datetime).format('DD/MM/YYYY') : '';
		},
		async init_options() {
			const { data } = await staffService.index(5);
			this.installers = data.map((d) => {
				return {
					text: d.name,
					value: d.id
				};
			});
		}
	},
	mounted() {
		this.assignData();
		this.init_options();
	},
	components: { CommentSection }
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col-md-6">
				<div
					class="card"
					style="padding: 1rem; height: 190.64px">
					<h5>Customer Details</h5>
					<div class="row">
						<span
							>Customer Name: {{ this.customer_details.customer_name }}</span
						>
						<span
							>Customer Location:
							{{ this.customer_details.customer_location }}</span
						>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div
					class="card"
					style="padding: 1rem">
					<h5>Vehicle Information</h5>
					<div class="row">
						<span>Brand: {{ this.vehicle_details.brand }}</span>
						<span>Model: {{ this.vehicle_details.model }}</span>
						<span>Registation No: {{ this.vehicle_details.veh_regis_no }}</span>
						<span
							>Transmission:
							{{ ['Auto', 'Manual'][this.vehicle_details.veh_trans] }}</span
						>
						<span
							>Road Tax Expiry: {{ this.vehicle_details.road_tax_expiry }}</span
						>
						<span v-if="this.vehicle_details.imei">IMEI: <a target="_blank" :href="`/fleet?imei=${this.vehicle_details.imei}`">{{ this.vehicle_details.imei }} (Click to see on map)</a> 
							&nbsp;
							<span v-if="imei_live == true" class="badge bg-success">Online</span>
							<b-spinner v-else-if="checking == true && imei_live == null" small variant="primary" role="status"></b-spinner>
							<span v-else class="badge bg-danger">Offline</span>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div
					class="card"
					style="padding: 1rem">
					<h5>Additional Information</h5>
					<div class="row">
						<div class="col-md-6">
							<b-form-group
								label="Color"
								label-for="veh_color"
								class="mb-3">
								<b-form-input
									:state="formValidation(veh_color)" required
									:disabled="edit_permission"
									@change="changes"
									v-model="veh_color"
									id="veh_color"
									type="text"></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-6">
							<b-form-group
								label="Odometer"
								label-for="odometer"
								class="mb-3">
								<b-form-input
									:state="formValidation(odometer)" required
									:disabled="edit_permission"
									@change="changes"
									v-model="odometer"
									id="odometer"
									type="text"></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-6">
							<b-form-group
								label="Spare Key"
								label-for="spare_key"
								class="mb-3">
								<b-form-input
									:state="formValidation(spare_key)" required
									:disabled="edit_permission"
									@change="changes"
									v-model="spare_key"
									id="spare_key"
									type="text"></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-6">
							<b-form-group
								label="Installer"
								label-for="installer_id">
								<b-form-select
									id="installer_id"
									style="
										padding: 0.47rem 0.75rem;
										font-size: 0.9rem;
										width: 100%;
										height: 100%;
									"
									:state="formValidation(installer_id)" required
									:disabled="edit_permission"
									@change="changes"
									v-model="installer_id"
									:options="installers"></b-form-select>
							</b-form-group>
						</div>
						<div class="col-md-6">
							<b-form-group
								label-for="book_date"
								class="mb-3">
								<div style="font-weight: 500;" v-if="role_id == 2">
									Book Date: <span v-if="!is_confirmed" class="badge bg-danger">A new booking is not yet confirmed</span>
									<span v-else-if="is_confirmed" class="badge bg-success">The booking date has been confirmed.</span>
								</div>
								<b-form-input
									:disabled="edit_permission"
									@change="changes"
									v-model="book_date"
									id="book_date"
									type="date"></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-6">
							<b-form-group
								label-for="book_time"
								class="mb-3">
								<div style="font-weight: 500;" v-if="role_id == 2">
									Book Time: <span v-if="!is_confirmed" class="badge bg-danger">A new booking is not yet confirmed</span>
									<span v-else-if="is_confirmed" class="badge bg-success">The booking date has been confirmed.</span>									
								</div>
								<b-form-input
									:disabled="edit_permission"
									@change="changes"
									v-model="book_time"
									id="book_time"
									type="time"></b-form-input>
							</b-form-group>
						</div>
					</div>
				</div>
			</div>
		</div>

		<br />
		<CommentSection
			v-if="form_data?.id"
			@newTimeAccepted="getAcceptedTime"
			:is_confirmed="is_confirmed"
			:comments="comments"
			:customer_id="form_data?.id"
			:status="2"
			@refresh="getData"
		/>
	</div>
</template>

<style lang="scss"></style>
