<template>
	<div class="row">
		<div class="col-md-12" v-if="!edit_permission" >
			<ul class="nav nav-pills" style="display: flex; justify-content: start">
				<b-form-select :disabled="edit_permission" v-model="selectedLoanType" @change="() => {
					loanSelection()
					calAll()
				}">
					<option value="">Select Type of Loan</option>
					<option value="partnerLoan">Refinancing</option>
					<option value="dealerLoan">New Financing</option>
				</b-form-select>
			</ul>
		</div>

		<div class="col-md-6">
			<b-card title="Loan Details" >
				<div v-if="selectedLoanType == 'dealerLoan'" class="row">
					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="vehiclePrice" label="Price of Vehicle:">
							<b-form-input type="number" id="vehiclePrice" @change="calAll" :disabled="edit_permission" v-model="vehiclePrice" placeholder="Enter vehicle price" required />						
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="deposit" label="Deposit:">
							<b-form-input type="number" id="deposit" @change="calAll" :disabled="edit_permission" v-model="deposit" placeholder="Enter Deposit" required />
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="insuranceDealer" label="Insurance:">
							<b-form-input type="number" id="insuranceDealer" @change="calAll" :disabled="edit_permission" v-model="insuranceDealer" placeholder="Enter Insurance" required />
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="interestDealer" label="Interest:">
							<b-form-input type="number" id="interestDealer" @change="calAll" :disabled="edit_permission" v-model="interestDealer" placeholder="Enter Interest" required />
						</b-form-group>
					</div>
				</div>

				<div v-if="selectedLoanType == 'partnerLoan'" class="row">
					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="cashoutPrice" label="Cash Out Price:">
							<b-form-input type="number" id="cashoutPrice" @change="calAll" :disabled="edit_permission" v-model="cashoutPrice" placeholder="Enter vehicle price" required />
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="devicePrice" label="Device Price:">
							<b-form-input type="number" id="devicePrice" @change="calAll" :disabled="edit_permission" v-model="devicePrice" placeholder="Enter number of years" required />
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="transferFee" label="JPJ Ownership Transfer Fee and Processing:">
							<b-form-input type="number" id="transferFee" @change="calAll" :disabled="edit_permission" v-model="transferFee" placeholder="Enter Transfer Fee" required />
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="stampFee" label="Stamping Fee:">
							<b-form-input type="number" id="stampFee" @change="calAll" :disabled="edit_permission" v-model="stampFee" placeholder="Enter Stamp Fee" required />
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="processFeePercentage" label="Process Fee Percentage:">
							<b-form-input type="number" id="processFeePercentage" @change="calAll" :disabled="edit_permission" v-model="processFeePercentage" placeholder="Enter Process Fee Percentage" required />
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="insurance" label="Insurance:">
							<b-form-input type="number" id="insurance" @change="calAll" :disabled="edit_permission" v-model="insurance" placeholder="Enter Insurance" required />
						</b-form-group>
					</div>

					<div class="col-md-12" style="margin: 0.25rem">
						<b-form-group for="interestRate" label="Interest:">
							<b-form-input type="number" id="interestRate" @change="calAll" :disabled="edit_permission" v-model="interestRate" placeholder="Enter Interest" required />
						</b-form-group>
					</div>
				</div>
			</b-card>
		</div>

		<div class="col-md-6">
			<b-card>
				<b-card-title>
					<div style="display: flex;justify-content: space-between;">
						<span>Summary</span>
					</div>
				</b-card-title>

				<div v-if="selectedLoanType === 'partnerLoan'">
					<p>Cash price: <span>{{ renderAmount(sellingPrice) }}</span></p>
					<p>Deposit Customer: <span>{{ renderAmount(depositCustomer) }}</span></p>
					<p>Total Loan: <span>{{ renderAmount(totalLoan) }}</span></p>
					<p>Total Interest Charge: <span>{{ renderAmount(totalInterestCharge) }}</span></p>
					<p>Total Hire Purchase Price: <span>{{ renderAmount(totalHirePurchase) }}</span></p>
					<p>GPS Monthly: <span>{{ renderAmount(gpsMonthly) }}</span></p>
					<p>Monthly Loan Payment: <span>{{ renderAmount(monthlyLoanPayment) }}</span></p>
					<p>Monthly Payment: <span>{{ renderAmount(monthlyPayment) }}</span></p>
				</div>

				<div v-if="selectedLoanType === 'dealerLoan'">
					<p>Selling Price: <span>{{ renderAmount(sellingPriceDealer) }}</span></p>
					<p>Deposit Customer: <span>{{ renderAmount(depositCustomerDealer) }}</span></p>
					<p>Special Rebate: <span>{{ renderAmount(specialRebateDealer) }}</span></p>
					<p>Total Loan: <span>{{ renderAmount(totalLoanDealer) }}</span></p>
					<p>Total Charge: <span>{{ renderAmount(totalChargeDealer) }}</span></p>
					<p>Total Hire Purchase Price: {{ renderAmount(totalHirePurchaseDealer) }}</p>
					<p>Monthly Payment: <span>{{ renderAmount(monthlyPaymentDealer) }}</span></p>
				</div>
			</b-card>
		</div>

		<div class="col-md-12" style="margin: 0.25rem">
			<b-tabs v-if="!edit_permission && form_data?.status != 4" v-model="selectedYears" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
				<b-tab active>
					<template v-slot:title>
						<span class="d-none d-sm-inline-block">1 YEAR</span>
					</template>
				</b-tab>
				<b-tab active>
					<template v-slot:title>
						<span class="d-none d-sm-inline-block">2 YEAR</span>
					</template>
				</b-tab>
				<b-tab active>
					<template v-slot:title>
						<span class="d-none d-sm-inline-block">3 YEAR</span>
					</template>
				</b-tab>
			</b-tabs>
			<b-tabs v-else v-model="selectedYears" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
				<b-tab active>
					<template v-slot:title>
						<span class="d-none d-sm-inline-block">{{ displayYear }} YEAR</span>
					</template>
				</b-tab>
			</b-tabs>
			<b-table style="text-align: center;height: 600px;overflow-y: scroll;" :items="loanScheduleList" :fields="fields" responsive="sm" >
				<template v-slot:cell(amount)="{ item }">
					{{ renderAmount(item.amount) }}
				</template>
				<template v-slot:cell(balance)="{ item }">
					{{ renderAmount(item.balance) }}
				</template>
			</b-table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoanScheduleList',
	props: {
		form_data: {
			type: Object,
			default: () => {}
		},
		edit_permission: {
			type: Boolean,
			default: false
		},
		loan: {
			type: Object,
			default: () => {}
		},
		role_id: {
			type: Number,
			default: 0
		},
	},
	data() {
		return {
			selectedLoanType: 'partnerLoan',
			selectedYears: 1,
			deposit: 0,

			vehiclePrice: 0,
			cashoutPrice: 0,
			devicePrice: 180,
			transferFee: 50,
			stampFee: 100,
			processFeePercentage: 3,
			interestDealer: 10,
			interestRate: 10,
			insurance: 0,
			insuranceDealer: 0,

			stampFeeDisabled: true,
			sellingPrice: 0,
			depositCustomer: 0,
			totalLoan: 0,
			totalInterestCharge: 0,
			totalHirePurchase: 0,
			gpsMonthly: 0,
			monthlyLoanPayment: 0,
			monthlyPayment: 0,

			sellingPriceDealer: 0,
			depositCustomerDealer: 0,
			specialRebateDealer: 0,
			totalLoanDealer: 0,
			totalChargeDealer: 0,
			totalHirePurchaseDealer: 0,
			monthlyPaymentDealer: 0,

			loanScheduleList: [],
			fields: [
				{ key: 'month', label: 'Month' },
				{ key: 'amount', label: 'Amount' },
				{ key: 'balance', label: 'Balance' }
			]
		};
	},
	computed: {
		displayYear() {
			return this.loan?.selected_years
		}
	},
	methods: {
		renderAmount(number) {
			return number? Number(number).toLocaleString('en-GB',{ maximumFractionDigits: 2 }):0
		},
		changes() {
			clearTimeout(this.changesTimeout);
			this.changesTimeout = setTimeout(() => {
				this.saveLoan();
			}, 2000);
		},
		saveLoan() {
			const partner = {
				loan_type: this.selectedLoanType,
				price: this.sellingPrice,
				deposit: this.depositCustomer,
				total_loan: this.totalLoan,
				total_interest: this.totalInterestCharge,
				total_hire_purchase: this.totalHirePurchase,
				gps_monthly: this.gpsMonthly,
				monthly_loan_payment: this.monthlyLoanPayment,
				monthly_payment: this.monthlyPayment,
				equation: JSON.stringify({
					cashoutPrice: this.cashoutPrice,
					devicePrice: this.devicePrice,
					transferFee: this.transferFee,
					stampFee: this.stampFee,
					processFeePercentage: this.processFeePercentage,
					interestRate: this.interestRate,
					insurance: this.insurance
				})
			}

			const dealer = {
				loan_type: this.selectedLoanType,
				price: this.sellingPriceDealer,
				deposit: this.depositCustomerDealer,
				rebate: this.specialRebateDealer,
				total_loan: this.totalLoanDealer,
				total_interest: this.totalChargeDealer,
				total_hire_purchase: this.totalHirePurchaseDealer,
				monthly_payment: this.monthlyPaymentDealer,
				equation: JSON.stringify({
					vehiclePrice: this.vehiclePrice,
					deposit: this.deposit,
					insurance: this.insuranceDealer,
					interest: this.interestDealer
				})
			}

			const loan = this.selectedLoanType === 'partnerLoan' ? partner : dealer;
			this.$emit('saveLoan', loan);

		},
		loanSelection() {
			if (this.selectedLoanType === 'partnerLoan') {
				this.stampFeeDisabled = false;
			} else {
				this.stampFeeDisabled = true;
			}
		},
		calculateGpsMonthlyFee(cashoutPrice) {
			if (cashoutPrice < 3001) {
				return 10;
			} else if (cashoutPrice < 6001) {
				return 15;
			} else if (cashoutPrice < 10001) {
				return 20;
			} else if (cashoutPrice < 20001) {
				return 25;
			} else {
				return 30;
			}
		},
		calculateStampFee() {
			// Implement the logic for calculating stamp fee if needed
			// Example: this.stampFee = ...;
		},
		calculateLoan() {
			const years = parseInt(this.selectedYears);
			this.sellingPriceDealer = 0; // Reset dealer results

			const vehiclePrice = parseFloat(this.vehiclePrice);
			const deposit = parseInt(this.deposit);
			const insurance = parseInt(this.insuranceDealer);
			const interestRate = parseInt(this.interestDealer);

			let sellingPrice;
			let depositCustomer;
			let specialRebate;
			let totalLoan;
			let totalCharge;
			let totalHirePurchase;
			let monthlyPayment;

			const hirePurchase = 0.1;
			const markUp = 0.22;

			if (deposit > 0) {
				sellingPrice =
					((vehiclePrice - deposit) * (markUp * years + 1)) /
					(1 + hirePurchase * years) +
					deposit;
			} else {
				sellingPrice =
					(vehiclePrice * (0.22 * years + 1)) / (1 + 0.1 * years) / 0.9;
			}

			if (deposit > 0) {
				depositCustomer = deposit;
			} else {
				depositCustomer = sellingPrice * 0.1;
			}

			if (deposit > 0) {
				specialRebate = 0;
			} else {
				specialRebate = depositCustomer;
			}

			totalLoan = sellingPrice - depositCustomer + insurance;
			totalCharge = totalLoan * (interestRate / 100) * years;
			totalHirePurchase = totalLoan + totalCharge + insurance;
			monthlyPayment = totalHirePurchase / (years * 12);

			this.sellingPriceDealer = sellingPrice;
			this.depositCustomerDealer = depositCustomer;
			this.specialRebateDealer = specialRebate;
			this.totalLoanDealer = totalLoan;
			this.totalChargeDealer = totalCharge;
			this.totalHirePurchaseDealer = totalHirePurchase;
			this.monthlyPaymentDealer = monthlyPayment;

			this.loanScheduleList = [];
			for (let i = 1; i <= years * 12; i++) {
				this.loanScheduleList.push({
					month: i,
					amount: Number(monthlyPayment),
					balance: Number(
						(totalHirePurchase - monthlyPayment * i)
					)
				});
			}
		},
		calculateEmicroLoan() {
			const years = parseInt(this.selectedYears);

			this.sellingPrice = 0;
			this.depositCustomer = 0;
			this.totalLoan = 0;
			this.totalInterestCharge = 0;
			this.totalHirePurchase = 0;
			this.gpsMonthly = 0;
			this.monthlyLoanPayment = 0;
			this.monthlyPayment = 0;

			const cashoutPrice = parseFloat(this.cashoutPrice);
			const devicePrice = parseInt(this.devicePrice);
			const transferFee = parseInt(this.transferFee);
			const processFeePercentage = parseInt(this.processFeePercentage);
			const interestRate = parseInt(this.interestRate);
			const insurance = parseInt(this.insurance);
			const stampFee = parseInt(this.stampFee);

			let sellingPrice;
			let depositCustomer;
			let totalLoan;
			let totalInterestCharge;
			let totalHirePurchase;
			let gpsMonthly;
			let monthlyLoanPayment;
			let monthlyPayment;

			const monthlyLoanPaymentAsPercentage =
				years === 1 ? 0.06 : years === 2 ? 0.08 : 1;

			if (years === 1) {
				totalLoan = (cashoutPrice * 1.36) / 1.1 + insurance;
			} else {
				totalLoan =
					((cashoutPrice +
						devicePrice +
						transferFee +
						stampFee +
						cashoutPrice * (processFeePercentage / 100)) *
						1.36) /
					1.1 +
					insurance;
			}

			if (years === 1) {
				sellingPrice = (cashoutPrice * 1.36) / 1.1 / 0.9;
			} else {
				sellingPrice = totalLoan / 0.9;
			}

			depositCustomer = sellingPrice * 0.1;

			if (years === 1) {
				totalInterestCharge = totalLoan * (interestRate / 100);
				totalHirePurchase = totalLoan + totalInterestCharge;
			} else {
				totalInterestCharge = sellingPrice * (interestRate / 100);
				totalHirePurchase = sellingPrice + totalInterestCharge;
			}

			const totalHirePurchaseWithDevice = totalHirePurchase + devicePrice;
			gpsMonthly = this.calculateGpsMonthlyFee(cashoutPrice);

			if (years === 1) {
				monthlyLoanPayment = cashoutPrice * monthlyLoanPaymentAsPercentage;
			} else if (years === 2) {
				const amount =
					cashoutPrice +
					devicePrice +
					transferFee +
					stampFee +
					cashoutPrice * (processFeePercentage / 100);
				monthlyLoanPayment = amount * monthlyLoanPaymentAsPercentage;
			} else if (years === 3) {
				monthlyLoanPayment = totalHirePurchase / 12;
			}

			monthlyPayment = monthlyLoanPayment + gpsMonthly;

			this.sellingPrice = sellingPrice;
			this.depositCustomer = depositCustomer;
			this.totalLoan = totalLoan;
			this.totalInterestCharge = totalInterestCharge;
			this.totalHirePurchase = totalHirePurchase;
			this.gpsMonthly = gpsMonthly;
			this.monthlyLoanPayment = monthlyLoanPayment;
			this.monthlyPayment = monthlyPayment;

			this.loanScheduleList = [];
			for (let i = 1; i <= 12; i++) {
				if (i !== 12) {
					this.loanScheduleList.push({
						month: i,
						amount: Number(monthlyPayment),
						balance: (
							totalHirePurchase -
							monthlyPayment * i +
							gpsMonthly * i
						)
					});
				}

				if (i === 12) {
					const monthlyPaymentAmount = monthlyPayment;
					const remainingAmount =
						totalHirePurchaseWithDevice - monthlyPayment * i;

					if (years !== 3) {
						monthlyPayment = remainingAmount + parseFloat(monthlyPaymentAmount);
					}

					this.loanScheduleList.push({
						month: i,
						amount: Number(monthlyPayment),
						balance: 0
					});
				}
			}
		},
		calAll() {
			if (this.loan?.selected_years) {
				this.selectedYears = this.loan?.selected_years
			}

			if (this.selectedYears) {
				if (this.selectedLoanType === 'partnerLoan') {
					this.calculateEmicroLoan();
				} else {
					this.calculateLoan();
				}
				
				this.changes()
			}
		},
		init_equation() {
			const { loan_type, equation, selected_years } = this.loan;
			const parsedEquation = JSON.parse(equation);
			
			if (loan_type) {
				if (loan_type === 'partnerLoan') {
					this.selectedLoanType = 'partnerLoan';
					this.selectedYears = selected_years
					this.cashoutPrice = parsedEquation.cashoutPrice;
					this.devicePrice = parsedEquation.devicePrice;
					this.transferFee = parsedEquation.transferFee;
					this.stampFee = parsedEquation.stampFee;
					this.processFeePercentage = parsedEquation.processFeePercentage;
					this.interestRate = parsedEquation.interestRate;
					this.insurance = parsedEquation.insurance;

					this.calculateEmicroLoan();

				} else {
					this.selectedLoanType = 'dealerLoan';
					this.selectedYears = selected_years
					this.vehiclePrice = parsedEquation.vehiclePrice;
					this.deposit = parsedEquation.deposit;
					this.insuranceDealer = parsedEquation.insurance;
					this.interestDealer = parsedEquation.interest;

					this.calculateLoan();
				}
			}
		}
	},
	mounted() {
		if (this.loan) {
			this.init_equation();
		}
	},
	watch: {
		selectedYears: function () {
			this.calAll()
		}
	}
};
</script>

<style scoped>
.col-form-label {
	padding-bottom: 0
}
</style>