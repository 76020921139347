<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import customerService from '@/api/customerService';
import Swal from 'sweetalert2';
import moment from 'moment';
import staffService from '@/api/staffService';
import CommentSection from '@/components/comment-section.vue';
import LoanScheduleList from '@/components/loan-schedule-list.vue';
import systemService from '@/api/systemService';

export default {
	name: 'require-install',
	props: {
		form_data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		role_id: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			customer_details: {},
			vehicle_details: {},
			loan_details: {},
			comments: [],
            loanScheduleList: [],
            fields: [
				{ key: 'month', label: 'Month' },
				{ key: 'monthlyPayment', label: 'Monthly Payment' },
				{ key: 'loanBalance', label: 'Loan Balance' },
                { key: 'payslip', label: 'Payment' },
                { key: 'paid_at', label: 'Paid At' }
			],
			checking: false,
			imei_live: null,
		};
	},
	computed: {
		customer_id() {
			return this.$route.query.id;
		}
	},
	methods: {
		formValidation(e, type = 'text') {
			const emailRegex = /\S+@\S+\.\S+/;

			switch (type) {
				case 'email':
				return e !== null && e !== undefined && e !== '' && emailRegex.test(e);
				default:
				return e !== null && e !== undefined && e !== '';
			}
		},
		saveRequireInstall() {
			const body = {
				veh_color: this.veh_color,
				odometer: this.odometer,
				spare_key: this.spare_key,
				installer_id: this.installer_id,
				remarks: this.remarks,
				book_datetime: this.book_date + ' ' + this.book_time
			};
			Swal.fire({
				title: 'Confirm',
				text: 'Are you sure you want to submit this vehicle?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then(async (result) => {
				if (result.value) {
					const response = await customerService.requireInstall(
						this.customer_id,
						body
					);
					if (response) {
						Swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 3000,
							icon: 'success'
						});
					}
				}
			});
		},
		detectData() {
			if (this.form_data) {
				const {
					name: customer_name,
					phone_no: customer_phone,
					location: customer_location,
					enquiry: { road_tax_expiry },
					comments
				} = this.form_data;
				this.customer_details = {
					customer_name,
					customer_phone,
					customer_location
				};
				if (this.form_data.vehicle) {
					const {
						vehicle: {
							brand: { name: vehicle_brand },
							vehicle_model: { name: vehicle_model },
							veh_regis_no,
							veh_trans,
							imei
						},
					} = this.form_data

					this.vehicle_details = {
						brand: vehicle_brand,
						model: vehicle_model,
						veh_regis_no,
						veh_trans,
						road_tax_expiry,
						imei
					};

					this.checkIMEI(imei)
				}

				this.comments = comments;
			}
		},
        getLoan(monthlyPayment, totalHirePurchase, years) {
            this.loanScheduleList = [];
			for (let i = 1; i <= years * 12; i++) {
				this.loanScheduleList.push({
					month: i,
					monthlyPayment: Number(monthlyPayment.toFixed(2)).toLocaleString(),
					loanBalance: Number(
						(totalHirePurchase - monthlyPayment * i).toFixed(2)
					).toLocaleString()
				});
			}
        },
		assignData() {
			setTimeout(() => {
				this.detectData();
			}, 1000);
		},
		show_date(datetime) {
			return datetime ? moment(datetime).format('DD/MM/YYYY') : '';
		},
		async init_options() {
			const { data } = await staffService.index(5);
			this.installers = data.map((d) => {
				return {
					text: d.name,
					value: d.id
				};
			});
		},
		async checkIMEI(imei) {
			this.checking = true
			await systemService.checkIMEI(imei)
				.then(res => {
					this.checking = false
					this.imei_live = res.data
				})
		},
	},
	mounted() {
		this.assignData();
		this.init_options();
	},
	components: { 
		CommentSection, LoanScheduleList
	}
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col-md-6">
				<div
					class="card"
					style="padding: 1rem; height: 190.64px">
					<h5>Customer Details</h5>
					<div class="row">
						<span
							>Customer Name: {{ this.customer_details.customer_name }}</span
						>
						<span
							>Customer Location:
							{{ this.customer_details.customer_location }}</span
						>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div
					class="card"
					style="padding: 1rem">
					<h5>Vehicle Information</h5>
					<div class="row">
						<span>Brand: {{ this.vehicle_details.brand }}</span>
						<span>Model: {{ this.vehicle_details.model }}</span>
						<span>Registation No: {{ this.vehicle_details.veh_regis_no }}</span>
						<span
							>Transmission:
							{{ ['Auto', 'Manual'][this.vehicle_details.veh_trans] }}</span
						>
						<span
							>Road Tax Expiry: {{ this.vehicle_details.road_tax_expiry }}</span
						>
						<span>IMEI: <a target="_blank" :href="`/fleet?imei=${this.vehicle_details.imei}`">{{ this.vehicle_details.imei }} (Click to see on map)</a>
							&nbsp;
							<span v-if="imei_live == true" class="badge bg-success">Online</span>
							<b-spinner v-else-if="checking == true && imei_live == null" small variant="primary" role="status"></b-spinner>
							<span v-else class="badge bg-danger">Offline</span>
						</span>
						
					</div>
				</div>
			</div>
            <div class="col-md-12" v-if="form_data">
				<LoanScheduleList 
					v-if="form_data.loan"
					:form_data="form_data"
					:role_id="role_id" 
					:loan="form_data?.loan"
					:edit_permission="false"
				/>
            </div>
		</div>

		<br />
		<CommentSection
			:submitAction="saveRequireInstall"
			:comments="comments" />
	</div>
</template>

<style lang="scss"></style>