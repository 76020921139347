<script>
import customerService from '@/api/customerService.js';
import systemService from '@/api/systemService.js';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	props: {
		form_data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		edit_permission: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			brands: [],
			vehicle_models: [],
			brand_id: null,
			vehicle_model_id: null,
			veh_trans: false,
			veh_regis_no: '',
			veh_year: '',
			veh_cc_engine: '',
			veh_chassis_no: '',
			veh_purchase_year: '',
			veh_location: '',
			veh_value: '',
			location_of_application: ''
		};
	},
	computed: {
		customer_id() {
			return this.$route.query.id;
		}
	},
	methods: {
		formValidation(e, type = 'text') {
			const emailRegex = /\S+@\S+\.\S+/;

			switch (type) {
				case 'email':
					return (
						e !== null && e !== undefined && e !== '' && emailRegex.test(e)
					);
				default:
					return e !== null && e !== undefined && e !== '';
			}
		},
		showAsOptions(arr, parent) {
			if (parent) {
				return arr
					.filter((item) => item[parent.key] === parent.id)
					.map((item) => {
						return {
							value: item.id,
							text: item.name
						};
					});
			} else {
				return arr.map((item) => {
					return {
						value: item.id,
						text: item.name
					};
				});
			}
		},
		async init_options() {
			this.brands = await systemService.getCommons('Brand');
			this.vehicle_models = await systemService.getCommons('VehicleModel');
		},
		saveVehicle(customer_id) {
			const body = {
				brand_id: this.brand_id,
				vehicle_model_id: this.vehicle_model_id,
				veh_trans: this.veh_trans,
				veh_regis_no: this.veh_regis_no,
				veh_year: this.veh_year,
				veh_cc_engine: this.veh_cc_engine,
				veh_chassis_no: this.veh_chassis_no,
				veh_purchase_year: this.veh_purchase_year,
				veh_location: this.veh_location,
				veh_value: this.veh_value,
				location_of_application: this.location_of_application
			};

			customerService.vehicle(customer_id, body);
		},
		detectData() {
			if (this.form_data) {
				this.brand_id = this.form_data.brand_id;
				this.vehicle_model_id = this.form_data.vehicle_model_id;
				this.veh_trans = this.form_data.veh_trans == 1 ? true : false;
				this.veh_regis_no = this.form_data.veh_regis_no;
				this.veh_year = this.form_data.veh_year;
				this.veh_cc_engine = this.form_data.veh_cc_engine;
				this.veh_chassis_no = this.form_data.veh_chassis_no;
				this.veh_purchase_year = this.form_data.veh_purchase_year;
				this.veh_location = this.form_data.veh_location;
				this.veh_value = this.form_data.veh_value;
				this.location_of_application = this.form_data.location_of_application;
			}
		},
		assignData() {
			setTimeout(() => {
				this.detectData();
			}, 1000);
		}
	},
	mounted() {
		this.init_options();
		this.assignData();
	}
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col-md-4">
				<b-form-group
					label="Car Brand"
					label-for="brand_id"
					class="mb-3">
					<b-form-select
						id="brand_id"
						style="
							padding: 0.47rem 0.75rem;
							font-size: 0.9rem;
							width: 100%;
							height: 100%;
						"
						:state="formValidation(brand_id)"
						:disabled="edit_permission" v-model="brand_id"
						:options="showAsOptions(brands)"></b-form-select>
				</b-form-group>
			</div>
			<div class="col-md-4">
				<b-form-group
					label="Car Models"
					label-for="vehicle_model_id"
					class="mb-3">
					<b-form-select
						id="vehicle_model_id"
						style="
							padding: 0.47rem 0.75rem;
							font-size: 0.9rem;
							width: 100%;
							height: 100%;
						"
						:state="formValidation(vehicle_model_id)"
						:disabled="edit_permission || !brand_id" v-model="vehicle_model_id"
						:options="
							showAsOptions(vehicle_models, { key: 'brand_id', id: brand_id })
						"></b-form-select>
				</b-form-group>
			</div>
			<div class="col-md-4">
				<b-form-group
					label="Transmission (Auto / Manual)"
					label-for="veh_trans"
					class="mb-3">
					<b-form-select
						id="veh_trans"
						style="
							padding: 0.47rem 0.75rem;
							font-size: 0.9rem;
							width: 100%;
							height: 100%;
						"
						:state="formValidation(veh_trans)"
						:disabled="edit_permission" v-model="veh_trans"
						:options="[
							{ value: false, text: 'Auto' },
							{ value: true, text: 'Manual' }
						]"></b-form-select>
				</b-form-group>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<b-form-group
					label="Vehicle Registration Number"
					label-for="veh_regis_no"
					class="mb-3">
					<b-form-input
					:state="formValidation(veh_regis_no)"	
					:disabled="edit_permission" v-model="veh_regis_no"
						id="veh_regis_no"
						type="text"></b-form-input>
				</b-form-group>
			</div>
			<div class="col-md-4">
				<b-form-group
					label="Year Manufactured / Registered"
					label-for="veh_year"
					class="mb-3">
					<b-form-input
					:state="formValidation(veh_year)"	
					:disabled="edit_permission" v-model="veh_year"
						id="veh_year"
						type="text"></b-form-input>
				</b-form-group>
			</div>
			<div class="col-md-4">
				<b-form-group
					label="CC Engine"
					label-for="cc_engine"
					class="mb-3">
					<b-form-input
					:state="formValidation(veh_cc_engine)"	
					:disabled="edit_permission" v-model="veh_cc_engine"
						id="veh_cc_engine"
						type="text"></b-form-input>
				</b-form-group>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<b-form-group
					label="Vehicle chassis Number"
					label-for="veh_chassis_no"
					class="mb-3">
					<b-form-input
					:state="formValidation(veh_chassis_no)"	
					:disabled="edit_permission" v-model="veh_chassis_no"
						id="veh_chassis_no"
						type="text"></b-form-input>
				</b-form-group>
			</div>
			<div class="col-md-4">
				<b-form-group
					label="Purchase Year"
					label-for="veh_purchase_year"
					class="mb-3">
					<b-form-input
					:state="formValidation(veh_purchase_year)"	
					:disabled="edit_permission" v-model="veh_purchase_year"
						id="veh_purchase_year"
						type="text"></b-form-input>
				</b-form-group>
			</div>
			<div class="col-md-4">
				<b-form-group
					label="Location of Car"
					label-for="veh_location"
					class="mb-3">
					<b-form-input
					:state="formValidation(veh_location)"	
					:disabled="edit_permission" v-model="veh_location"
						id="veh_location"
						type="text"></b-form-input>
				</b-form-group>
			</div>
		</div>
		<div class="row">
			<b-form-group
				label="Location of Application"
				label-for="location_of_application"
				class="mb-3">
				<b-form-input
				:state="formValidation(location_of_application)"	
				:disabled="edit_permission" v-model="location_of_application"
					id="location_of_application"
					type="text"></b-form-input>
			</b-form-group>
		</div>
		<div class="row">
			<b-form-group
				label="Estimated Vehicle Value"
				label-for="veh_value"
				class="mb-3">
				<b-form-input
				:state="formValidation(veh_value)"	
				:disabled="edit_permission" v-model="veh_value"
					id="veh_value"
					type="number"></b-form-input>
			</b-form-group>
		</div>
	</div>
</template>

<style lang="scss"></style>
